import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CategoryIcon from '@mui/icons-material/Category';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ROUTE_CONSTANTS } from 'src/constants/AppRoutes';
import { CarRepairOutlined, DashboardRounded, MessageOutlined, PeopleAltOutlined } from '@mui/icons-material';
const mastersCategory = {
    name: 'Masters',
    slug: 'masters',
    badgeTooltip: 'Masters',
    icon: CategoryIcon,
    isParent: true,
}
const takeVehicleInCategory = {
    name: 'Take Vehicle In',
    slug: 'take_vehicle_in',
    badgeTooltip: 'Take Vehicle In',
    icon: CarRepairOutlined,
    isParent: false,
    route: {
        name: 'Take Vehicle In',
        icon: CarRepairOutlined,
        badgeTooltip: 'Take Vehicle In',
        isParent: false,
        isMain: true,
        link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.ADD_NEW.ROOT.ABSOLUTE,
        permissionKey: 'BOOKING:CREATE',
    }
}
const controlPanelCategory = {
    name: 'Control Panel',
    slug: 'control_panel',
    badgeTooltip: 'Control Panel',
    icon: PeopleAltOutlined,
    isParent: false,
    route: {
        name: 'Control Panel',
        icon: PeopleAltOutlined,
        badgeTooltip: 'Control Panel',
        isParent: false,
        isMain: true,
        link: ROUTE_CONSTANTS.DASHBOARD.CONTROL_PANEL.ROOT.ABSOLUTE,
        permissionKey: 'CONFIGURATION:CONTROLPANEL',
    }
}
const smsCategory = {
    name: 'Send SMS',
    slug: 'dashboard',
    badgeTooltip: 'Send SMS',
    icon: MessageOutlined,
    isParent: false,
    route: {
        name: 'Send SMS',
        icon: MessageOutlined,
        badgeTooltip: 'Send SMS',
        isParent: false,
        isMain: true,
        link: ROUTE_CONSTANTS.DASHBOARD.SMS.ABSOLUTE,
        permissionKey: 'CONFIGURATION:SENDSMS',
    }
}



const dashboardCategory = {
    name: 'Dashboards',
    slug: 'dashboards',
    badgeTooltip: 'Dashboards',
    icon: DashboardIcon,
    isParent: true,
}
const ReportsCategory = {
    name: 'Reports',
    slug: 'reports',
    badgeTooltip: 'Reports',
    icon: AssessmentIcon,
    isParent: true,
}

// ***** IMPORTANT NOTE ABOUT EXPOSING CATEGORIES ****  orders of defining categories inside export object has a direct effect on orders in sidebar
export const sidebarCategoryObjectsCashier = {
    // DRIVER_DASHBOARD,
    controlPanelCategory,
    takeVehicleInCategory,
    dashboardCategory,
    smsCategory,
    ReportsCategory,
    mastersCategory,
    
}