import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import { Grid, Card, Typography, useMediaQuery, useTheme, Box, Button, Icon, Dialog, IconButton, DialogTitle, DialogContent, DialogActions, Rating, TextField, CircularProgress } from "@mui/material";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import ClientLogo from "src/components/LogoSign/clientLogo";
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import CarLeft from '../../assets/images/carLeft.png'
import CarMid from '../../assets/images/carMid.png'
import sampleAds from '../../assets/images/sampleAds.png'
import SuspenseLoader from "src/components/layouts/SuspenseLoader";
import { CloseOutlined, WarningAmberOutlined } from "@mui/icons-material";
import html2canvas from 'html2canvas';
import './GlobalCssClient.css';
import dayjs from "dayjs";
import { Formik } from "formik";
import * as Yup from 'yup'

const ClientSide = () => {
  const { toast } = useToast();
  const location = useLocation();
  const parameters = location.pathname.split("/");
  const theme = useTheme();
  const {token} = useParams()
  let hash = parameters[parameters.length - 1];
  const [details, setDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isAdsOpen, setIsAdsOpen] = useState(true)
  const [confirmModal, setConfirmModal] = useState(false)
  const reportTemplateRef = useRef(null);
  const [feedbackDialog, setFeedbackDialog] = useState(false)
  const [rating, setRating] = useState(0)
  const [showTerms, setShowTerms] = useState(false)
  const [html, setHtml] = useState({ __html: "" });

  const getData = async (isLoadable = true) => {
    try {
      if (isLoadable) setIsLoading(true)
      let res = await adminService.getClientSideInvoice(hash);
      console.log(res);
      setDetails(res.data.data)
      setHtml({ __html: res.data.data.terms_and_conditions || '' });
      setIsLoading(false)
    } catch (error) {
      toast(error.response.data.message, "error");
      console.log(error);
      setIsLoading(false)
    }
  };

  const requestCar = async () => {
    try {
      setIsLoading(true)
      const res = await adminService.requestCar(hash)
      getData(true)
      setConfirmModal(false)
    } catch (error) {
      toast(error.response.data.message, "error");
      setIsLoading(false)
    }
  }

  const hexToRgbA = (hex, opacity) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
    }
    throw new Error('Bad Hex');
  };

  useEffect(() => {
    getData(true);
    // TODO: check if nessesary to renew data by time
    // setInterval(() => {
    //   getData(false);
    // }, 10000)
  }, []);

  const downloadReceipt = () => {
    html2canvas(document.querySelector("#capture")).then(canvas => {
      // document.body.appendChild(canvas)
      var a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = 'somefilename.jpg';
        a.click();
  });
  }

  const submitFeedback = async(formData, resetForm, setErrors, setStatus, setSubmitting) => {
        formData['rating'] = +(formData['rating'])
        try {
            const response = await adminService.sendFeedback(token ,formData)
            toast(response.data.message, 'success')
            setSubmitting(false);
            resetForm();
            getData(true);
            setStatus({ success: true });
            setFeedbackDialog(false)

        } catch (err) {
            toast(err?.response?.data?.message || 'Network Error!', 'error')
            setSubmitting(false);
            console.log(err);
        }
  }

  return (
    <>
      {
        isLoading ? <SuspenseLoader></SuspenseLoader> :
          details ?
          details.BookingStatusName != 'DELIVERED' ?
          <Box display="flex" justifyContent="center">
            <Grid container display="flex" justifyContent="center" style={{ maxWidth: '600px' }}>

              <Box style={{ marginBottom: -100, justifyContent: 'center', display: 'flex', zIndex: 10 }} >
                <ClientLogo />
              </Box>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                pr={'20px'}
                pl={'20px'}
              >
                <Card
                  style={{ marginTop: "1rem", minHeight: "200px", paddingTop: '80px', display: 'flex', justifyContent: 'center', }}
                >
                  <Grid container>
                    <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                      <Typography fontFamily={'Noto Nastaliq Urdu'} fontSize={24} fontWeight={400} >
                        طلب السيارة
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                      <Typography fontSize={14} fontWeight={800}>
                        Request Your Car
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }} pt={4}>
                      <Button style={{ backgroundColor: '#217AFF', color: 'white', height: 34 }}>
                        Key Tag: {details?.keytag}
                      </Button>
                    </Grid>

                    <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }} pt={4}>
                      <Typography fontSize={14} style={{ textAlign: 'center', color: '#2C2C2C' }}>
                        <div dangerouslySetInnerHTML={{__html: `<div>${details?.text.replace('\n', "</br>")}</div>`}}></div>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }} pt={4}>
                      <Typography fontSize={14} style={{ textAlign: 'center', color: '#2C2C2C' }}>
                        Status: <br />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }} pt={2} pb={2}>

                      <Box display={'flex'} flexDirection={'row'} pt={'2px'} pb={'2px'} pl={'10px'} pr={'10px'} borderRadius={1} backgroundColor={hexToRgbA(details?.bookingStatusColor || '#111111', 0.1)}>
                        <DriveEtaOutlinedIcon style={{ color: `${details?.bookingStatusColor || '#111111'}`, textShadow: 'black 0px 0px 3px' }} />
                        <Typography fontSize={14} style={{ textAlign: 'center', color: `${details?.bookingStatusColor || '#111111'}` }}>
                          {details?.BookingStatusName}
                        </Typography>
                      </Box>

                    </Grid>
                    {details?.canRequest &&
                      <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }} pt={1} pb={1}>
                        <Button variant="contained" onClick={() => setConfirmModal(true)}>
                          Request Car
                        </Button>
                      </Grid>
                    }

                    {details?.BookingStatusName == 'READY' ? <Grid xs={12} style={{ justifyContent: 'center', display: 'flex' }} pt={1} pb={2}>
                      <img src={CarMid} />
                    </Grid> : details.BookingStatusName != 'DELIVERED' ? <img src={CarLeft} /> : <Grid xs={12} style={{ justifyContent: 'right', display: 'flex' }} pt={1} pb={2}>
                      <img src={CarMid} style={{marginRight: -60}}/>
                    </Grid>}
                  </Grid>

                </Card>

                <Dialog open={confirmModal} >
                  <DialogContent sx={{p: 3}} >
                    <WarningAmberOutlined sx={{color: 'orange'}}/>
                    <Typography fontSize={'20px'} fontWeight={600}>
                      Are you sure you want to request your car?
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button variant='outlined' onClick={() => setConfirmModal(false)} >
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={() => requestCar()}>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>

                <Button fullWidth variant='text' onClick={() => setShowTerms(true)}>Terms & Conditions</Button>
                {
                  details?.advs?.map((adv) => {
                    return <Dialog open={isAdsOpen} key={adv.id} fullWidth className="ads-dialog"
                      onClose={() => setIsAdsOpen(false)}>
                      {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        {adv.title}
                      </DialogTitle> */}
                      {/* <IconButton
                        aria-label="close"
                        onClick={() => setIsAdsOpen(false)}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseOutlined />
                      </IconButton> */}
                      <DialogContent sx={{p: 0}}>
                        <a href={adv.link} alt={adv.title}><img src={adv?.medias && adv?.medias?.length ? adv?.medias[0]?.url : ''} alt="ads" style={{ maxWidth: "100%", maxHeight: "calc(100vh - 186px)" }} /></a>
                        <Button style={{ backgroundColor: '#217AFF', color: 'white', height: 34, width: '100%' }} onClick={() => setIsAdsOpen(false)}>
                          {"Close"}
                        </Button>
                      </DialogContent>
                    </Dialog>
                    
                    // <a href={adv.link} key={adv.id}><img src={sampleAds} alt="ads" width={'100%'} /></a>
                  })
                }

              </Grid>
            </Grid>
          </Box>: 
          
          <Box display="flex" justifyContent="center" id="capture">
            <Grid container display="flex" justifyContent="center" style={{ maxWidth: '600px' }}>

            <Box style={{ marginBottom: -100, justifyContent: 'center', display: 'flex', zIndex: 10 }} >
              <ClientLogo />
            </Box>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              pr={'20px'}
              pl={'20px'}
            >
              <Card
                style={{ marginTop: "1rem", minHeight: "200px", paddingTop: '80px', display: 'flex', justifyContent: 'center', paddingLeft: '20px',paddingRight: '20px' }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ justifyContent: 'left', display: 'flex' }}>
                    <Typography fontSize={24} fontWeight={700} >
                      Receipt Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{
                      p: 1,
                      background: 'rgba(0,0,0,0.04)',
                      borderRadius: 1,
                    }}> 

                      <Box sx={{
                        display: 'flex',
                        marginBottom: 2,
                        justifyContent: 'space-between'
                        
                      }}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}>
                          <Typography fontWeight={700}>
                            Ticket Number
                          </Typography>
                          <Typography>
                            {details?.ticketNumber || '-----'}
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right'
                        }}>
                          <Typography fontWeight={700}>
                            Key tag
                          </Typography>
                          <Typography>
                            {details?.keytag || '---'}
                          </Typography>
                        </Box>
                        
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 2,
                      }}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}>
                          <Typography fontWeight={700}>
                            Parking Location
                          </Typography>
                          <Typography>
                            {details?.branchName || '-----'}
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right'
                        }}>
                          <Typography fontWeight={700}>
                            Parking Block
                          </Typography>
                          <Typography>
                            {details?.parkingBlock || '---'}
                          </Typography>
                        </Box>
                        
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        marginBottom: 2,
                        justifyContent: 'space-between'
                      }}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                          <Typography fontWeight={700}>
                            Checked In
                          </Typography>
                          <Typography>
                            {dayjs(details?.checkedIn).format('YYYY-MM-DD HH:mm') || '-----'}
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right'
                        }}>
                          <Typography fontWeight={700}>
                            Checked Out
                          </Typography>
                          <Typography>
                            {dayjs(details?.checkedOut).format('YYYY-MM-DD HH:mm') || '---'}
                          </Typography>
                        </Box>
                        
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        marginBottom: 2,
                        justifyContent: 'space-between'
                      }}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                          <Typography fontWeight={700}>
                            Customer Phone
                          </Typography>
                          <Typography>
                            {details?.phone || '-----'}
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right'
                        }}>
                          <Typography fontWeight={700}>
                            Customer Name
                          </Typography>
                          <Typography>
                            {details?.name || '---'}
                          </Typography>
                        </Box>
                        
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        marginBottom: 2,
                        justifyContent: 'space-between'
                      }}>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                          <Typography fontWeight={700}>
                            Payment Amount
                          </Typography>
                          <Typography>
                            {details?.amount || '-----'}
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'right'
                        }}>
                          <Typography fontWeight={700}>
                            Payment Option
                          </Typography>
                          <Typography>
                            {details?.paymentMethod || '---'}
                          </Typography>
                        </Box>
                        
                      </Box>
                      
                    </Box>

                  </Grid>
                  <Grid item xs={12} style={{ justifyContent: 'left', display: 'flex' }}>
                    <Typography fontSize={15} fontWeight={400} >
                      Thank You
                      <br/>
                      For Parking with us
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                      >
                      <Button variant='outlined' onClick={() => downloadReceipt()}>
                        Download Receipt
                      </Button>
                      {details.can_feedback ? <Button variant='outlined' onClick={() => setFeedbackDialog(true)}>
                        Feedback
                      </Button> : <></>}
                    </Box>
                  </Grid>

                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography>
                        Support Phone: {details?.supportPhone || '-----------'}
                      </Typography>
                  </Grid>
                </Grid>

              </Card>

              <Button fullWidth variant='text' onClick={() => setShowTerms(true)}>Terms & Conditions</Button>

              <Dialog open={feedbackDialog} onClose={() => setFeedbackDialog(false)} >
                <DialogTitle>Feedback</DialogTitle>
                <DialogContent>

                  <Formik
                            initialValues={{
                                rating: 0,
                                message: '',
                                subject: '',
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    subject: Yup.string()
                                        .required('The subject field is required'),
                                    message: Yup.string()
                                        .required('the message field is required'),
                                })
                            }
                            onSubmit={async (
                                _values,
                                { resetForm, setErrors, setStatus, setSubmitting }
                            ) =>  submitFeedback(_values, resetForm, setErrors, setStatus, setSubmitting) }
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3} marginBottom={2} >
                                      <Grid item xs={12}>
                                        <Rating name="rating" defaultValue={values.rating} size="large" onChange={handleChange} onBlur={handleBlur} />
                                      </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched?.subject && errors.subject
                                                )}
                                                fullWidth
                                                required
                                                helperText={touched?.subject && errors.subject}
                                                label={'Subject'}
                                                name="subject"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.subject}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                error={Boolean(
                                                    touched?.message && errors.message
                                                )}
                                                fullWidth
                                                required
                                                helperText={touched?.message && errors.message}
                                                label={'Message'}
                                                name="message"
                                                multiline
                                                rows={4}
                                                maxRows={5}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.message}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container style={{ margin: '20px 0px' }} alignItems="center">
                                        <Button color="secondary" onClick={() => setFeedbackDialog(false)}>
                                            {'Cancel'}
                                        </Button>
                                        <Button
                                            type="submit"
                                            startIcon={
                                                isSubmitting ? <CircularProgress size="1rem" /> : null
                                            }
                                            disabled={Boolean(errors.submit) || isSubmitting}
                                            variant="contained"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                </DialogContent>
              </Dialog>

              {
                details?.advs?.map((adv) => {
                  return <Dialog open={isAdsOpen} key={adv.id} fullWidth className="ads-dialog"
                    onClose={() => setIsAdsOpen(false)}>
                    
                    <DialogContent sx={{p: 0}}>
                        <a href={adv.link} alt={adv.title}><img src={adv?.medias && adv?.medias?.length ? adv?.medias[0]?.url : ''} alt="ads" style={{ maxWidth: "100%", maxHeight: "calc(100vh - 186px)" }} /></a>
                        <Button style={{ backgroundColor: '#217AFF', color: 'white', height: 34, width: '100%' }} onClick={() => setIsAdsOpen(false)}>
                          {"Close"}
                        </Button>
                      </DialogContent>
                  </Dialog>
                  
                  // <a href={adv.link} key={adv.id}><img src={sampleAds} alt="ads" width={'100%'} /></a>
                })
              }
            </Grid>
            </Grid>
          </Box>
          : <Box
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              fontSize: 24
            }}
          >
            Sorry, this link is not working at the moment!
          </Box>
      }

            <Dialog open={showTerms} fullWidth className="ads-dialog">
              <DialogContent>
                <Box sx={{direction: 'ltr'}}>
                  <div style={{direction: 'ltr'}} dangerouslySetInnerHTML={html}>
                  </div>
                </Box>
                <Button style={{ backgroundColor: '#217AFF', color: 'white', height: 34, width: '100%' }} onClick={() => setShowTerms(false)}>
                  {"Close"}
                </Button>
              </DialogContent>
            </Dialog>
    </>

  );
};
export default ClientSide;
