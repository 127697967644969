import { AxiosInstance, RawAxiosInstance } from "../index";

export const authService = {
    login(username, password) {
        return AxiosInstance.post("/v1/users/signin", {
            username,
            password
        })
    },
    logout() {
        return AxiosInstance.get("/v1/users/signOut")
    },
    forgotPassword(body) {
        return AxiosInstance.post("/v1/users/forgotpassword", body);
    },
    checkResetHash(token) {
        return AxiosInstance.get(`/v1/users/check-reset-password?token=${token}`);
    },
    resetPassword(body) {
        return RawAxiosInstance.post("/v1/users/resetPassword", body);
    },
    getProfile: () =>  AxiosInstance.get("/v1/users/me")
};