import {
    Fragment,
    forwardRef,
    Ref,
    useState,
    ReactElement,
    ChangeEvent,
    useEffect,
    useRef
} from 'react';
import {
    Link,
    Box,
    Divider,
    IconButton,
    List,
    Button,
    CircularProgress,
    ListItem,
    Grid,
    InputBase,
    Tooltip,
    Typography,
    Card,
    Dialog,
    alpha,
    Slide,
    styled,
    useTheme
} from '@mui/material';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import AppSettingsAltTwoToneIcon from '@mui/icons-material/AppSettingsAltTwoTone';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import Scrollbar from 'src/components/layouts/Scrollbar';
import { adminService } from 'src/api/services/admin';
import { ROUTE_CONSTANTS } from 'src/constants/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { BookingStatuses } from 'src/pages/dashboard/ControlPanel/Details/constants';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import { OS } from 'src/hooks/Platform';

const wait = (time) =>
    new Promise((res) => setTimeout(res, time));

const Transition = forwardRef(function Transition(
    props, { children },
    ref
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-container {
          height: auto;
      }
      
      .MuiDialog-paperScrollPaper {
          max-height: calc(100vh - 64px)
      }
  `
);

const SearchInputWrapper = styled(InputBase)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(18)};
      padding: ${theme.spacing(2)};
      width: 100%;
  `
);

const ListButton = styled(Box)(
    ({ theme }) => `
        background-color: transparent;
        color:  ${theme.colors.alpha.black[100]};
        transition: ${theme.transitions.create(['all'])};
        border: ${theme.colors.alpha.black[10]} solid 1px;
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(1)};
        margin: ${theme.spacing(1, 0)};
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        & > div > .MuiSvgIcon-root {
          color:  ${theme.colors.alpha.black[50]};
          transition: ${theme.transitions.create(['all'])};
        }
  
        &:hover {
          background-color: ${alpha(theme.colors.primary.main, 0.08)};
          color:  ${theme.colors.primary.main};
          border-color: ${alpha(theme.colors.primary.main, 0.3)};
  
          & > div > .MuiSvgIcon-root {
            color:  ${theme.colors.primary.main};
          }
        }
  `
);

const SearchInputWrapperButton = styled(Button)(
    ({ theme }) => `
    font-weight: normal;
    background: ${theme.colors.primary.main};
    border:  ${alpha(theme.colors.alpha.white[30], 0.2)} solid 1px;
    margin-left: 10px;
    &:hover {
      background: ${alpha(theme.colors.primary.main, 0.90)};
      border: ${theme.colors.alpha.white[30]} solid 1px;
    }
  `
);

const BtnControl = styled(Box)(
    ({ theme }) => `
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px !important;
    background: ${alpha(theme.colors.alpha.white[100], 0.8)};
    color: ${theme.colors.alpha.black[100]};
    border-radius: ${theme.general.borderRadiusSm};
    padding: 0 6px;
    margin-left: ${theme.spacing(2)};
  `
);

const searchTerms = {
    Dashboards: [
        {
            title: 'Automation UI'
        },
        {
            title: 'Banking Performance'
        },
        {
            title: 'Fitness Statistics'
        }
    ],
    Applications: [
        {
            title: 'Events Manager'
        },
        {
            title: 'Messenging Platform'
        }
    ],
    Management: [
        {
            title: 'Products Admin'
        },
        {
            title: 'Customers Database'
        },
        {
            title: 'Learning Center'
        },
        {
            title: 'Invoices Archive'
        }
    ]
};

function HeaderSearch() {
    const theme = useTheme();

    const [searchValue, setSearchValue] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchResults, setSearchResults] = useState(false);
    const [searchDetails, setSearchDetails] = useState([])
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const os = OS(window)

    const { flushHeldKeys } = useKeyboardShortcut(
        [os == 'MacOS' ? 'Meta' : 'Alt', "k"],
        shortcutKeys => handleClickOpen(),
        {
            overrideSystem: false,
            ignoreInputFields: false,
            repeatOnHold: false
        }
    );

    const submitSearch = async (event) => {
        event.preventDefault();
    };

    const handleSearchChange = async (event) => {
        event.preventDefault();

        if (event.target.value) {
            setSearchResults(false);
            setSearchValue(event.target.value);
            setSearchLoading(true);
            const response = await adminService.searchBookings(event.target.value)
            setSearchDetails(response.data.data)

            setSearchLoading(false);
            setSearchResults(true);
        } else {
            setSearchValue('');
            setSearchResults(false);
        }
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setTimeout(() => {
            inputRef.current.focus();
        }, 10)
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const handleKeyDown = (nativeEvent) => {

            if (
                ['KeyK'].indexOf(nativeEvent.code) !== -1 &&
                document.activeElement !== inputRef.current
            ) {
                // handleClickOpen()
                // nativeEvent.preventDefault();
                // setTimeout(() => {
                //     inputRef.current.focus();
                // }, 10)
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleOpenDetails = (result) => {
        navigate(ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.GET_BY_DATA(result.id).ABSOLUTE, { state: result, replace: true })
        setOpen(false);
    }

    return (
        <>
            <SearchInputWrapperButton
                sx={{
                    display: {
                      lg: 'flex',
                      md: 'flex',
                      xs: 'none'
                    }
                  }}
                variant="contained"
                disableElevation
                onClick={handleClickOpen}
                startIcon={<SearchTwoToneIcon />}
                endIcon={<BtnControl>{os == 'MacOS' ? <KeyboardCommandKeyIcon style={{ fontSize: 12, marginBottom: -2 }} /> : 'Ctrl+'}K</BtnControl>}
            >
                {'Search...'}
            </SearchInputWrapperButton>

            <Button sx={{
                    display: {
                      lg: 'none',
                      md: 'none',
                      xs: 'flex'
                    }
                  }}
                  onClick={handleClickOpen}
                  >
                <SearchTwoToneIcon />
            </Button>
        
            <DialogWrapper
                open={open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth="sm"
                fullWidth
                scroll="paper"
                onClose={handleClose}
            >
                <Box>
                    <form onSubmit={submitSearch}>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1} display="flex" alignItems="center">
                                <SearchTwoToneIcon
                                    sx={{
                                        ml: 2,
                                        color: theme.colors.secondary.main
                                    }}
                                />
                                <SearchInputWrapper
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                    inputRef={inputRef}
                                    autoFocus
                                    inputProps={{
                                        type: "text",
                                        inputMode: "numeric",
                                        pattern: "d*",
                                        min: 0,
                                        maxLength: 5
                                      }}
                                    placeholder={'Search terms here...'}
                                    fullWidth
                                />
                            </Box>
                            <Card
                                sx={{
                                    ml: 'auto',
                                    mr: 2,
                                    py: 0.5,
                                    px: 1,
                                    background: theme.colors.alpha.black[10]
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontWeight="bold"
                                >
                                    esc
                                </Typography>
                            </Card>
                        </Box>
                    </form>
                </Box>
                <Divider />
                {!searchLoading && (
                    <>
                        {!searchResults && (
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                    background: theme.colors.info.lighter,
                                    color: theme.colors.info.main,
                                    borderRadius: theme.general.borderRadius,
                                    fontSize: theme.typography.pxToRem(13),
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1,
                                    mx: 2,
                                    my: 2
                                }}
                            >
                                <ContactSupportTwoToneIcon
                                    sx={{
                                        mr: 0.8,
                                        fontSize: theme.typography.pxToRem(18)
                                    }}
                                />
                                {'Start typing to see the search results...'}
                            </Typography>
                        )}
                    </>
                )}
                {searchLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            my: 5
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {searchResults ? (
                            <Box
                                sx={{
                                    height: 450
                                }}
                            >
                                <Scrollbar>
                                    {searchDetails.map((type, index) => (
                                        type?.items?.length > 0 && <Box px={2} py={1} key={index}>
                                            <Typography
                                                sx={{
                                                    py: 1
                                                }}
                                                variant="h5"
                                            >
                                                By {type.title}
                                            </Typography>
                                            {type.items.map((result) => (
                                                <Fragment key={result.id}>
                                                    <ListButton onClick={() => handleOpenDetails(result)} style={{borderColor: result?.bookingStatus?.color || '#F1f1f1'}}>
                                                        <Box alignItems="flex-start">
                                                            <Box display={'flex'}>
                                                                <ReceiptIcon
                                                                    sx={{
                                                                        mr: 1,
                                                                        color: result?.bookingStatus?.color || '#F1f1f1'
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                                <Typography>#BID-{result?.id}</Typography>

                                                            </Box>
                                                            <Typography>key tag : {result?.keyTag?.code}</Typography>

                                                            <Typography>name : {result?.name || '----'}</Typography>
                                                            <Typography>phone : {result?.phone || '----'}</Typography>
                                                            <Typography>car plate : {result?.BookingVehicle?.length > 0 && result?.BookingVehicle[0].carPlate || '----'}</Typography>
                                                        </Box>
                                                        <Box display={'flex'}>
                                                            <Typography fontWeight={800} color={result?.bookingStatus?.color || '#F1f1f1'}>{result?.bookingStatus?.title || 'unknown'}</Typography>
                                                            <KeyboardArrowRightTwoToneIcon fontSize="small" />
                                                        </Box>

                                                    </ListButton>
                                                </Fragment>
                                            ))}
                                        </Box>
                                    ))}
                                </Scrollbar>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    height: 450
                                }}
                            >
                            </Box>
                        )}
                    </>
                )}
            </DialogWrapper>
        </>
    );
}

export default HeaderSearch;
