const Permission = {
    PARKING_LOCATION_CREATE: 'PARKING_LOCATION:CREATE',
    PARKING_LOCATION_READ: 'PARKING_LOCATION:READ',
    PARKING_LOCATION_DETAILS: 'PARKING_LOCATION:DETAILS',
    PARKING_LOCATION_UPDATE: 'PARKING_LOCATION:UPDATE',
    PARKING_LOCATION_DELETE: 'PARKING_LOCATION:DELETE',
    ROLE_GROUP_CREATE: 'ROLE_GROUP:CREATE',
    ROLE_GROUP_READ: 'ROLE_GROUP:READ',
    ROLE_GROUP_DETAILS: 'ROLE_GROUP:DETAILS',
    ROLE_GROUP_UPDATE: 'ROLE_GROUP:UPDATE',
    ROLE_GROUP_DELETE: 'ROLE_GROUP:DELETE',
    PERMISSION_CREATE: 'PERMISSION:CREATE',
    PERMISSION_READ: 'PERMISSION:READ',
    PERMISSION_DETAILS: 'PERMISSION:DETAILS',
    PERMISSION_UPDATE: 'PERMISSION:UPDATE',
    PERMISSION_DELETE: 'PERMISSION:DELETE',
    ADMIN_CREATE: 'ADMIN:CREATE',
    ADMIN_READ: 'ADMIN:READ',
    ADMIN_DETAILS: 'ADMIN:DETAILS',
    ADMIN_UPDATE: 'ADMIN:UPDATE',
    ADMIN_DELETE: 'ADMIN:DELETE',
    CUSTOMER_CREATE: 'CUSTOMER:CREATE',
    CUSTOMER_READ: 'CUSTOMER:READ',
    CUSTOMER_DETAILS: 'CUSTOMER:DETAILS',
    CUSTOMER_UPDATE: 'CUSTOMER:UPDATE',
    CUSTOMER_DELETE: 'CUSTOMER:DELETE',
    BOOKING_CREATE: 'BOOKING:CREATE',
    BOOKING_READ: 'BOOKING:READ',
    BOOKING_DETAILS: 'BOOKING:DETAILS',
    BOOKING_UPDATE: 'BOOKING:UPDATE',
    SUBSCRIPTION_CREATE: 'SUBSCRIPTION:CREATE',
    SUBSCRIPTION_READ: 'SUBSCRIPTION:READ',
    SUBSCRIPTION_DETAILS: 'SUBSCRIPTION:DETAILS',
    SUBSCRIPTION_UPDATE: 'SUBSCRIPTION:UPDATE',
    PARKING_BLOCK_CREATE: 'PARKING_BLOCK:CREATE',
    PARKING_BLOCK_READ: 'PARKING_BLOCK:READ',
    PARKING_BLOCK_DETAILS: 'PARKING_BLOCK:DETAILS',
    PARKING_BLOCK_UPDATE: 'PARKING_BLOCK:UPDATE',
    PARKING_BLOCK_DELETE: 'PARKING_BLOCK:DELETE',
    KEY_TAG_CREATE: 'KEY_TAG:CREATE',
    KEY_TAG_READ: 'KEY_TAG:READ',
    KEY_TAG_DETAILS: 'KEY_TAG:DETAILS',
    KEY_TAG_UPDATE: 'KEY_TAG:UPDATE',
    KEY_TAG_DELETE: 'KEY_TAG:DELETE',
    CAR_MANUFACTURER_CREATE: 'CAR_MANUFACTURER:CREATE',
    CAR_MANUFACTURER_READ: 'CAR_MANUFACTURER:READ',
    CAR_MANUFACTURER_DETAILS: 'CAR_MANUFACTURER:DETAILS',
    CAR_MANUFACTURER_UPDATE: 'CAR_MANUFACTURER:UPDATE',
    CAR_MANUFACTURER_DELETE: 'CAR_MANUFACTURER:DELETE',
    CAR_MODEL_CREATE: 'CAR_MODEL:CREATE',
    CAR_MODEL_READ: 'CAR_MODEL:READ',
    CAR_MODEL_DETAILS: 'CAR_MODEL:DETAILS',
    CAR_MODEL_UPDATE: 'CAR_MODEL:UPDATE',
    CAR_MODEL_DELETE: 'CAR_MODEL:DELETE',
    BANNER_CREATE: 'BANNER:CREATE',
    BANNER_READ: 'BANNER:READ',
    BANNER_DETAILS: 'BANNER:DETAILS',
    BANNER_UPDATE: 'BANNER:UPDATE',
    BANNER_DELETE: 'BANNER:DELETE',
    COMPANY_CREATE: 'COMPANY:CREATE',
    COMPANY_READ: 'COMPANY:READ',
    COMPANY_DETAILS: 'COMPANY:DETAILS',
    COMPANY_UPDATE: 'COMPANY:UPDATE',
    COMPANY_DELETE: 'COMPANY:DELETE',
    SERVICE_CREATE: 'SERVICE:CREATE',
    SERVICE_READ: 'SERVICE:READ',
    SERVICE_DETAILS: 'SERVICE:DETAILS',
    SERVICE_UPDATE: 'SERVICE:UPDATE',
    SERVICE_DELETE: 'SERVICE:DELETE',
    CURRENCY_CREATE: 'CURRENCY:CREATE',
    CURRENCY_READ: 'CURRENCY:READ',
    CURRENCY_DETAILS: 'CURRENCY:DETAILS',
    CURRENCY_UPDATE: 'CURRENCY:UPDATE',
    CURRENCY_DELETE: 'CURRENCY:DELETE',
    GROUPS_CREATE: 'GROUPS:CREATE',
    GROUPS_READ: 'GROUPS:READ',
    GROUPS_DETAILS: 'GROUPS:DETAILS',
    GROUPS_UPDATE: 'GROUPS:UPDATE',
    GROUPS_DELETE: 'GROUPS:DELETE',
    CONFIGURATION_CONTROLPANEL: 'CONFIGURATION:CONTROLPANEL',
    CONFIGURATION_READ: 'CONFIGURATION:READ',
    CONFIGURATION_SEARCH: 'CONFIGURATION:SEARCH',
    CONFIGURATION_SENDSMS: 'CONFIGURATION:SENDSMS',
    CONFIGURATION_UPDATEVEHICLE: 'CONFIGURATION:UPDATEVEHICLE',
    CONFIGURATION_UPDATEPARKING: 'CONFIGURATION:UPDATEPARKING',
    CONFIGURATION_UPDATECUSTOMER: 'CONFIGURATION:UPDATECUSTOMER',
    CONFIGURATION_UPDATEPARKER: 'CONFIGURATION:UPDATEPARKER',
    REPORT_LANDLORD: 'REPORT:LANDLORD',
    REPORT_CHECKINOUT: 'REPORT:CHECKINOUT',
    REPORT_FEEDBACK: 'REPORT:FEEDBACK',
    REPORT_CLOSING: 'REPORT:CLOSING',
    REPORT_INCOME: 'REPORT:INCOME',
    REPORT_CUSTOMER: 'REPORT:CUSTOMER',
    REPORT_INCOMESUMMARY: 'REPORT:INCOMESUMMARY',
    REPORT_DRIVERS: 'REPORT:DRIVERS',
    REPORT_HOURWISE: 'REPORT:HOURWISE',
    REPORT_KEYTAG: 'REPORT:KEYTAG',
    REPORT_SERVICE: 'REPORT:SERVICE',
    REPORT_VOUCHER: 'REPORT:VOUCHER',
    ALL: 'ALL',
}
module.exports = Permission