import * as Yup from 'yup';

import { Formik } from 'formik';
import { Link as RouterLink, Navigate, useLocation } from 'react-router-dom';
import { authService } from 'src/api/services/auth'
import { useSetUser, useUser, useSetTokens, useSetPermission, useMenu } from "src/contexts/GlobalContext";
import { ROUTE_CONSTANTS } from 'src/constants/AppRoutes'
import useToast from 'src/hooks/useToast'
import Logo from './components/Logo';

import {
    Box,
    Button,
    TextField,
    Link,
    CircularProgress,
    Typography,
} from '@mui/material';
import useRefMounted from '../../hooks/useRefMounted';

const Login = () => {
    const location = useLocation()
    const setUser = useSetUser();
    const setMenu = useMenu();
    const setPermission = useSetPermission();
    const setTokens = useSetTokens()
    const user = useUser();
    const { toast } = useToast()
    const isMountedRef = useRefMounted();

    if (user) {
        if(user?.role?.name == 'DRIVER') return <Navigate to={ROUTE_CONSTANTS.DASHBOARD.CUSTOM_DASHBOARD.ABSOLUTE} replace/>
        if (location.state?.previousPath) return <Navigate to={location.state.previousPath} replace /> //**** redirect user to path entered before login
        else return <Navigate to={ROUTE_CONSTANTS.DASHBOARD.ANALYTICS.ABSOLUTE} replace />
    }
    return (
        <Box
            sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                style={{
                    maxWidth: '100%',
                    width: 400
                }}>
                <Logo />
                <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center' }}>
                    Login
                </Typography>
                <Formik
                    initialValues={{
                        username: '',
                        password: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .max(255)
                            .required('The username field is required'),
                        password: Yup.string()
                            .min(6)
                            .max(255)
                            .required('The password field is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            const res = await authService.login(values.username, values.password)
                            console.log('login res',res)
                            setPermission(res.data.data.menu.permissions)
                            setTokens(res.data.data.accessToken, res.data.data.refreshToken)
                            setUser(res.data.data.admin)
                            if (res?.data?.data?.admin?.AssignedBranches?.length) {
                                localStorage.setItem('branchId', res?.data?.data?.admin?.AssignedBranches[0].branchId)
                            }else {
                                let resMe = await authService.getProfile()
                                if (resMe?.data?.data?.admin?.AssignedBranches?.length) {
                                    localStorage.setItem('branchId', resMe?.data?.data?.admin?.AssignedBranches[0].branchId)
                                }
                                setMenu(resMe.data.data.menu.items)
                                setUser(resMe.data.data.admin)
                                setPermission(resMe.data.data.menu.permissions)
                            }
                            toast('login success!', 'success')
                            if (isMountedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err) {
                            if (isMountedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                                toast(err.response.data.message, 'error')
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                error={Boolean(touched?.username && errors.username)}
                                fullWidth
                                margin="normal"
                                autoFocus
                                helperText={touched?.username && errors.username}
                                label={'Username'}
                                name="username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                                variant="outlined"
                            />
                            <TextField
                                error={Boolean(touched?.password && errors.password)}
                                fullWidth
                                margin="normal"
                                helperText={touched?.password && errors.password}
                                label={'Password'}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="password"
                                value={values.password}
                                variant="outlined"
                            />
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Link component={RouterLink} to={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.ABSOLUTE}>
                                    <b>{'Lost password?'}</b>
                                </Link>
                            </Box>

                            <Button
                                sx={{
                                    mt: 3
                                }}
                                color="primary"
                                startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                                disabled={isSubmitting}
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                            >
                                {'Sign in'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default Login;
