import React, { Suspense, lazy } from 'react'
import {
    Routes as RoutesWrapper,
    Route,
    Navigate,
} from "react-router-dom"
import Login from 'src/pages/auth/Login'
import ResetPassword from 'src/pages/auth/ResetPassword'
import ForgotPassword from 'src/pages/auth/ForgotPassword'
import NotFound from 'src/pages/404'
import NotPermitted from 'src/pages/NotPermitted'
import StaticPage from 'src/pages/statics/StaticPage'
import ClientSide from 'src/pages/client/clientSide'

import AccentSidebarLayout from 'src/components/layouts/AccentSidebarLayout'
import GeneralLayout from 'src/components/layouts/GeneralLayout'
import DashboardLayout from 'src/components/layouts/DashboardLayout'
import SuspenseLoader from 'src/components/layouts/SuspenseLoader'
import WrapperComponent from 'src/pages/dashboard/components/WrapperComponent'
import { ROUTE_CONSTANTS } from 'src/constants/AppRoutes'
import ProtectedRoutes from './ProtectedRoutes';
import * as Permission from './roles.enum'


const Analytics = Loader(lazy(() => import('src/pages/dashboard/Analytics')))

// **** user pages ***** 
const MyProfile = Loader(lazy(() => import('src/pages/dashboard/masters/MyProfile')))
const AdminList = Loader(lazy(() => import('src/pages/dashboard/masters/Admins')))
const UserDetails = Loader(lazy(() => import('src/pages/dashboard/masters/Details')))

const CustomerList = Loader(lazy(() => import('src/pages/dashboard/masters/Customers')))
const CustomerDetails = Loader(lazy(() => import('src/pages/dashboard/masters/Customers/components/Details')))



// **** catalog pages ***** 
const Product = Loader(lazy(() => import('src/pages/dashboard/catalog/Product')))
const AddUpdateProduct = Loader(lazy(() => import('src/pages/dashboard/catalog/Product/AddUpdatePage')))
const ProductDetails = Loader(lazy(() => import('src/pages/dashboard/catalog/Product/ProductDetails')))
const CarManufacturer = Loader(lazy(() => import('src/pages/dashboard/masters/CarManufacturers')))
const AddUpdateCarManufacturer = Loader(lazy(() => import('src/pages/dashboard/masters/CarManufacturers/AddUpdatePage')))

const CarModel = Loader(lazy(() => import('src/pages/dashboard/masters/CarModel')))
const AddUpdateCarModel = Loader(lazy(() => import('src/pages/dashboard/masters/CarModel/AddUpdatePage')))

const RoleGroup = Loader(lazy(() => import('src/pages/dashboard/Administration/RoleGroups')))
const AddUpdateRoleGroup = Loader(lazy(() => import('src/pages/dashboard/Administration/RoleGroups/AddUpdatePage')))

const Organizations = Loader(lazy(() => import('src/pages/dashboard/Administration/Organizations')))
const AddUpdateOrganizations = Loader(lazy(() => import('src/pages/dashboard/Administration/Organizations/AddUpdatePage')))

const Category = Loader(lazy(() => import('src/pages/dashboard/catalog/Category')))
const AddUpdateCategory = Loader(lazy(() => import('src/pages/dashboard/catalog/Category/AddUpdatePage')))

const Location = Loader(lazy(() => import('src/pages/dashboard/masters/Locations')))
const AddUpdateLocation = Loader(lazy(() => import('src/pages/dashboard/masters/Locations/AddUpdatePage')))

const Floor = Loader(lazy(() => import('src/pages/dashboard/masters/Floors')))
const AddUpdateFloor = Loader(lazy(() => import('src/pages/dashboard/masters/Floors/AddUpdatePage')))

const ParkingBlock = Loader(lazy(() => import('src/pages/dashboard/masters/ParkingBlocks')))
const AddUpdateParkingBlock = Loader(lazy(() => import('src/pages/dashboard/masters/ParkingBlocks/AddUpdatePage')))

const KeyTag = Loader(lazy(() => import('src/pages/dashboard/masters/KeyTags')))
const AddUpdateKeyTag = Loader(lazy(() => import('src/pages/dashboard/masters/KeyTags/AddUpdatePage')))
const KeyTagDetails = Loader(lazy(() => import('src/pages/dashboard/masters/KeyTags/Details')))

const Subscriptions = Loader(lazy(() => import('src/pages/dashboard/masters/Subscriptions')))
const AddSubscriptions = Loader(lazy(() => import('src/pages/dashboard/masters/Subscriptions/AddUpdatePage')))

const Advertisements = Loader(lazy(() => import('src/pages/dashboard/masters/Advertisements')))
const AddAdvertisements = Loader(lazy(() => import('src/pages/dashboard/masters/Advertisements/AddUpdatePage')))

const Companies = Loader(lazy(() => import('src/pages/dashboard/masters/Company')))
const AddUpdateCompany = Loader(lazy(() => import('src/pages/dashboard/masters/Company/AddUpdatePage')))

const Services = Loader(lazy(() => import('src/pages/dashboard/masters/Service')))
const AddUpdateServices = Loader(lazy(() => import('src/pages/dashboard/masters/Service/AddUpdatePage')))

const Bookings = Loader(lazy(() => import('src/pages/dashboard/masters/Booking')))
const AddUpdateBooking = Loader(lazy(() => import('src/pages/dashboard/masters/Booking/AddUpdatePage')))
const UpdateVehicleDetails = Loader(lazy(() => import('src/pages/dashboard/masters/Booking/AddUpdatePage/updateVehicleDetails')))
const UpdateLocationDetails = Loader(lazy(() => import('src/pages/dashboard/masters/Booking/AddUpdatePage/updateLocationDetails')))
const BookingDetails = Loader(lazy(() => import('src/pages/dashboard/masters/Booking/Details')))
const BookingPrint = Loader(lazy(() => import('src/pages/dashboard/masters/Booking/Details/components/Print')))

const ControlPanel = Loader(lazy(() => import('src/pages/dashboard/ControlPanel')))
const Sms = Loader(lazy(() => import('src/pages/dashboard/Sms')))

const Configurations = Loader(lazy(() => import('src/pages/dashboard/Administration/Configuration')))

// **** logistic pages ***** 
const Shop = Loader(lazy(() => import('src/pages/dashboard/logistics/Shop')))
const AddUpdateShop = Loader(lazy(() => import('src/pages/dashboard/logistics/Shop/AddUpdatePage')))
const ShopDetails = Loader(lazy(() => import('src/pages/dashboard/logistics/Shop/ShopDetails')))
const Parcels = Loader(lazy(() => import('src/pages/dashboard/logistics/Parcels')))
const ParcelDetails = Loader(lazy(() => import('src/pages/dashboard/logistics/Parcels/ParcelDetails')))
const Orders = Loader(lazy(() => import('src/pages/dashboard/logistics/Orders')))
const OrderDetails = Loader(lazy(() => import('src/pages/dashboard/logistics/Orders/OrderDetails')))
const Coupon = Loader(lazy(() => import('src/pages/dashboard/logistics/Coupon')))
const AddUpdateCoupon = Loader(lazy(() => import('src/pages/dashboard/logistics/Coupon/AddCoupons')))

// **** store front pages ***** 
const Collection = Loader(lazy(() => import('src/pages/dashboard/storeFront/Collection')))
const AddUpdateCollection = Loader(lazy(() => import('src/pages/dashboard/storeFront/Collection/AddUpdatePage')))
const CollectionDetails = Loader(lazy(() => import('src/pages/dashboard/storeFront/Collection/CollectionDetails')))

const Slide = Loader(lazy(() => import('src/pages/dashboard/storeFront/Slide')))
const AddUpdateSlide = Loader(lazy(() => import('src/pages/dashboard/storeFront/Slide/AddUpdatePage')))
const Banner = Loader(lazy(() => import('src/pages/dashboard/storeFront/Banner')))
const AddUpdateBanner = Loader(lazy(() => import('src/pages/dashboard/storeFront/Banner/AddUpdatePage')))
const AppHome = Loader(lazy(() => import('src/pages/dashboard/storeFront/AppHome')))
const AddUpdateAppHome = Loader(lazy(() => import('src/pages/dashboard/storeFront/AppHome/AddUpdatePage')))

// **** reports **** 
const LandLords = Loader(lazy(() => import('src/pages/dashboard/Reports/LandLord')))
const CheckInOut = Loader(lazy(() => import('src/pages/dashboard/Reports/CheckInOut')))
const Income = Loader(lazy(() => import('src/pages/dashboard/Reports/Income')))
const CloseShift = Loader(lazy(() => import('src/pages/dashboard/Reports/CloseShift')))
const DriverReports = Loader(lazy(() => import('src/pages/dashboard/Reports/Drivers')))
const KeyTagReports = Loader(lazy(() => import('src/pages/dashboard/Reports/KeyTags')))
const HourWiseReport = Loader(lazy(() => import('src/pages/dashboard/Reports/Hourwise')))
const IncomeSummaryReport = Loader(lazy(() => import('src/pages/dashboard/Reports/IncomeSummary')))
const VoucherReport = Loader(lazy(() => import('src/pages/dashboard/Reports/Voucher')))
const ServiceReport = Loader(lazy(() => import('src/pages/dashboard/Reports/Service')))
const Feedbacks = Loader(lazy(() => import('src/pages/dashboard/masters/Feedback')))
const FeedbacksDetail = Loader(lazy(() => import('src/pages/dashboard/masters/Feedback/AddUpdatePage')))

const SmsProviders = Loader(lazy(() => import('src/pages/dashboard/Administration/SmsProvider')))

// **** system pages ***** 
const Setting = Loader(lazy(() => import('src/pages/dashboard/system/Setting')))
const EditStaticPages = Loader(lazy(() => import('src/pages/dashboard/system/EditStaticPages')))

const CustomDashboard = Loader(lazy(() => import('src/pages/dashboard/Custom')))

function Loader(Component) {
    return (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    )
}

const AppRouter = () => {
    return (
        <RoutesWrapper>
            <Route path={ROUTE_CONSTANTS.ROOT.ABSOLUTE} element={<GeneralLayout />}>
                <Route path="" element={<Login />} />
            </Route>
            <Route path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE} element={<NotFound />} />
            <Route path={ROUTE_CONSTANTS.PUBLIC_ROUTES.NOT_PERMITTED.ABSOLUTE} element={<NotPermitted />} />
            <Route path={ROUTE_CONSTANTS.PUBLIC_ROUTES.STATIC_PAGES.ROOT.ABSOLUTE} element={<StaticPage />} />
            <Route path={ROUTE_CONSTANTS.PUBLIC_ROUTES.CLIENT_PAGE.ROOT.ABSOLUTE} element={<ClientSide />} />
            <Route path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE} element={<GeneralLayout />} >
                <Route path="" element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />} />
                <Route path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} element={<Login />} />
                <Route path={ROUTE_CONSTANTS.AUTH.RESET_PASSWORD.RELATIVE} element={<ResetPassword />} />
                <Route path={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.RELATIVE} element={<ForgotPassword />} />
            </Route>
            <Route path={""} element={<DashboardLayout />} >
                <Route path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE} element={<AccentSidebarLayout />}>
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.ANALYTICS.RELATIVE} element={<Analytics />} />
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.CUSTOM_DASHBOARD.RELATIVE} element={<CustomDashboard />} />
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.USERS.PROFILE.ROOT.RELATIVE} element={<MyProfile />} />
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.ROOT.RELATIVE} element={<WrapperComponent category='masters' />}>
                        <Route index element={<ProtectedRoutes role={`${Permission.ADMIN_READ}`}><AdminList /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.USERS.ADMINS.ROOT.ABSOLUTE} element={<ProtectedRoutes role='ADMIN:READ'><AdminList /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.USERS.DETAILS.ABSOLUTE} element={<ProtectedRoutes role='ADMIN:READ'><UserDetails /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.CUSTOMERS.ROOT.RELATIVE} element={<ProtectedRoutes role='CUSTOMER:READ'><CustomerList /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.CUSTOMERS.DETAILS.ABSOLUTE} element={<ProtectedRoutes role='CUSTOMER:READ'><CustomerDetails /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.CAR_MANUFACTURER.ROOT.RELATIVE} element={<ProtectedRoutes role="CAR_MANUFACTURER:READ"><CarManufacturer /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.CAR_MANUFACTURER.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='CAR_MANUFACTURER:CREATE'><AddUpdateCarManufacturer addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.CAR_MANUFACTURER.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='CAR_MANUFACTURER:UPDATE'><AddUpdateCarManufacturer addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.CAR_MODEL.ROOT.RELATIVE} element={<ProtectedRoutes role="CAR_MODEL:READ"><CarModel /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.CAR_MODEL.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='CAR_MODEL:CREATE'><AddUpdateCarModel addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.CAR_MODEL.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='CAR_MODEL:UPDATE'><AddUpdateCarModel addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_LOCATION.ROOT.RELATIVE} element={<ProtectedRoutes role="PARKING_LOCATION:READ"><Location /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_LOCATION.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='PARKING_LOCATION:CREATE'><AddUpdateLocation addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_LOCATION.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='PARKING_LOCATION:UPDATE'><AddUpdateLocation addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.ROOT.RELATIVE} element={<ProtectedRoutes role="BOOKING:READ"><Bookings /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='BOOKING:CREATE'><AddUpdateBooking addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='BOOKING:UPDATE'><AddUpdateBooking addNew={false} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.UPDATE_VEHICLE.ROOT.RELATIVE} element={<ProtectedRoutes role='BOOKING:UPDATE'><UpdateVehicleDetails addNew={false} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.UPDATE_LOCATION.ROOT.RELATIVE} element={<ProtectedRoutes role='BOOKING:UPDATE'><UpdateLocationDetails addNew={false} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.PRINT.ROOT.RELATIVE} element={<ProtectedRoutes role='BOOKING:READ'><BookingPrint addNew={false} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.DETAILS.ROOT.RELATIVE} element={<ProtectedRoutes role='BOOKING:READ'><BookingDetails /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_FLOOR.ROOT.RELATIVE} element={<ProtectedRoutes role="PARKING_FLOOR:READ"><Floor /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_FLOOR.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='PARKING_FLOOR:CREATE'><AddUpdateFloor addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_FLOOR.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='PARKING_FLOOR:UPDATE'><AddUpdateFloor addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_BLOCK.ROOT.RELATIVE} element={<ProtectedRoutes role="PARKING_BLOCK:READ"><ParkingBlock /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_BLOCK.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='PARKING_BLOCK:CREATE'><AddUpdateParkingBlock addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_BLOCK.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='PARKING_BLOCK:UPDATE'><AddUpdateParkingBlock addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.KEY_TAG.ROOT.RELATIVE} element={<ProtectedRoutes role="KEY_TAG:READ"><KeyTag /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.KEY_TAG.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='KEY_TAG:CREATE'><AddUpdateKeyTag addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.KEY_TAG.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='KEY_TAG:UPDATE'><AddUpdateKeyTag addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.SUBSCRIPTION.ROOT.RELATIVE} element={<ProtectedRoutes role="SUBSCRIPTION:READ"><Subscriptions /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.SUBSCRIPTION.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='SUBSCRIPTION:CREATE'><AddSubscriptions addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.SUBSCRIPTION.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='SUBSCRIPTION:UPDATE'><AddSubscriptions addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.ADVERTISEMENT.ROOT.RELATIVE} element={<ProtectedRoutes role="BANNER:READ"><Advertisements /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.ADVERTISEMENT.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='BANNER:CREATE'><AddAdvertisements addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.ADVERTISEMENT.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='BANNER:UPDATE'><AddAdvertisements addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.COMPANY.ROOT.RELATIVE} element={<ProtectedRoutes role="COMPANY:READ"><Companies /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.COMPANY.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='COMPANY:CREATE'><AddUpdateCompany addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.COMPANY.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='COMPANY:UPDATE'><AddUpdateCompany addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.SERVICE.ROOT.RELATIVE} element={<ProtectedRoutes role="SERVICE:READ"><Services /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.SERVICE.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='SERVICE:CREATE'><AddUpdateServices addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.MASTERS.SERVICE.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='SERVICE:UPDATE'><AddUpdateServices addNew={false} /></ProtectedRoutes>} />
                    </Route>
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE} element={<WrapperComponent category='dashboards' />}>
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.CONTROL_PANEL.ROOT.RELATIVE} element={<ProtectedRoutes role='CONFIGURATION:CONTROLPANEL'><ControlPanel /></ProtectedRoutes>} />
                    </Route>
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE} element={<WrapperComponent category='dashboards' />}>
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.SMS.RELATIVE} element={<ProtectedRoutes role='CONFIGURATION:SENDSMS'><Sms /></ProtectedRoutes>} />
                    </Route>
                    <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.ROOT.ABSOLUTE} element={<WrapperComponent category='REPORTS' />}>
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.LANDLORDS.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:LANDLORD'><LandLords /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.CHECKINOUTREPORT.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:CHECKINOUT'><CheckInOut /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.INCOMEREPORT.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:INCOME'><Income /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.CLOSESHIFTREPORT.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:CLOSING'><CloseShift /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.DRIVER_REPORTS.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:DRIVERS'><DriverReports /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.KEYTAG_REPORTS.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:KEYTAG'><KeyTagReports /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.HOURWISE_REPORTS.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:HOURWISE'><HourWiseReport /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.INCOMESUMMARY_REPORTS.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:INCOMESUMMARY'><IncomeSummaryReport /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.VOUCHER_REPORTS.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:VOUCHER'><VoucherReport /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.SERVICE_REPORTS.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:SERVICE'><ServiceReport /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.FEEDBACKS.ROOT.RELATIVE} element={<ProtectedRoutes role='REPORT:FEEDBACK'><Feedbacks /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.REPORTS.FEEDBACKS.DETAILS.ABSOLUTE} element={<ProtectedRoutes role='REPORT:FEEDBACK'><FeedbacksDetail /></ProtectedRoutes>} />
                    </Route>

                    <Route path={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.ROOT.RELATIVE} element={<WrapperComponent category='ADMINISTRATION' />}>

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.ROLE_GROUP.ROOT.RELATIVE} element={<ProtectedRoutes role="ROLE_GROUP:READ"><RoleGroup /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.ROLE_GROUP.ADD_NEW.ROOT.RELATIVE} element={<ProtectedRoutes role='ROLE_GROUP:CREATE'><AddUpdateRoleGroup addNew={true} /></ProtectedRoutes>} />
                        <Route path={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.ROLE_GROUP.UPDATE.ROOT.RELATIVE} element={<ProtectedRoutes role='ROLE_GROUP:UPDATE'><AddUpdateRoleGroup addNew={false} /></ProtectedRoutes>} />

                        <Route path={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.SMS_PROVIDERS.ROOT.RELATIVE} element={<ProtectedRoutes role="CONFIGURATION:SENDSMS"><SmsProviders /></ProtectedRoutes>} />


                        {/* <Route index element={<ProtectedRoutes role='GET_SETTINGS'><Setting /></ProtectedRoutes>} /> */}
                        {/* <Route path={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.SETTING.ROOT.RELATIVE} element={<ProtectedRoutes role='GET_SETTINGS'><Setting /></ProtectedRoutes>} /> */}
                        {/* <Route path={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.STATIC_PAGES.ROOT.RELATIVE} element={<ProtectedRoutes role='GET_STATIC_PAGE_LIST'><EditStaticPages /></ProtectedRoutes>} /> */}
                    </Route>
                    {/* <Route path={ROUTE_CONSTANTS.DASHBOARD.EDIT_STATIC_PAGES.ROOT.ABSOLUTE} element={<ProtectedRoutes role='GET_STATIC_PAGE_LIST'><EditStatics /></ProtectedRoutes>} /> */}
                </Route>
            </Route>
        </RoutesWrapper>
    )

}
export default AppRouter
