
import { ROUTE_CONSTANTS } from 'src/constants/AppRoutes'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DashboardCustomizeOutlined from '@mui/icons-material/DashboardCustomizeOutlined';
import PeopleAltOutlined from '@mui/icons-material/PeopleAltOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import LayersIcon from '@mui/icons-material/Layers';
import PinDropIcon from '@mui/icons-material/PinDrop';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Groups3Icon from '@mui/icons-material/Groups3';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ApartmentOutlined, CarRepairOutlined, MessageOutlined } from '@mui/icons-material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
// ***** user routes ***** 

const bookingsRoute = {
  categorySlug: 'masters',
  name: 'Bookings',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.ROOT.ABSOLUTE,
  badgeTooltip: 'Bookings',
  icon: BookOnlineIcon,
  permissionKey: 'BOOKING:READ'
}

const SubscriptionRoute = {
  categorySlug: 'masters',
  name: 'Subscriptions',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.SUBSCRIPTION.ROOT.ABSOLUTE,
  badgeTooltip: 'Permissions',
  icon: CardMembershipIcon,
  permissionKey: 'SUBSCRIPTION:READ'
}

// ***** Reports routes *****
const incomeReportRoute = {
  categorySlug: 'reports',
  name: 'Income',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.INCOMEREPORT.ROOT.ABSOLUTE,
  badgeTooltip: 'Income report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:INCOME'
}
const CloseShiftReportRoute = {
  categorySlug: 'reports',
  name: 'Shift Closing',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.CLOSESHIFTREPORT.ROOT.ABSOLUTE,
  badgeTooltip: 'Shift Closing',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:CLOSING'
}
const KeyTagReportRoute = {
  categorySlug: 'reports',
  name: 'Key Tag',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.KEYTAG_REPORTS.ROOT.ABSOLUTE,
  badgeTooltip: 'Key Tag Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:KEYTAG'
}

const VoucherReportRoute = {
  categorySlug: 'reports',
  name: 'Voucher',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.VOUCHER_REPORTS.ROOT.ABSOLUTE,
  badgeTooltip: 'Voucher Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:VOUCHER'
}

const FeedbackReportRoute = {
  categorySlug: 'reports',
  name: 'Feedback',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.FEEDBACKS.ROOT.ABSOLUTE,
  badgeTooltip: 'Feedback Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:FEEDBACK'
}

// ***** dashboards routes ***** //

const analyticsRoute = {
  categorySlug: 'dashboards',
  name: 'Analytics',
  link: ROUTE_CONSTANTS.DASHBOARD.ANALYTICS.ABSOLUTE,
  badgeTooltip: 'Analytics',
  icon: DashboardCustomizeOutlined,
  permissionKey: 'KEY_TAG:READ'
}



// ***** system routes  *****


// ***** IMPORTANT NOTE ABOUT EXPOSING ROUTES ****  orders of defining routes inside export object has direct effect on their orders in sidebar
export const sidebarRoutesObjectsCashier = {
  // *** user routes 
  bookingsRoute,

  incomeReportRoute,
  CloseShiftReportRoute,
  KeyTagReportRoute,
  VoucherReportRoute,
  FeedbackReportRoute,

  SubscriptionRoute,

  analyticsRoute,

}