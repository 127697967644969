import React from 'react'
import { Box, Typography } from '@mui/material'
import { sidebarCategoryObjects } from './SidebarCategoryObjects'
import SidebarCategories from './SidebarCategories'
import Scrollbars from 'react-custom-scrollbars-2'
import { useUser } from 'src/contexts/GlobalContext'
import { sidebarCategoryObjectsDriver } from './SidebarCategoryObjectsDriver'
import { sidebarCategoryObjectsCashier } from './SidebarCategoryObjectsCashier'

const SidebarMenu = () => {
  const user = useUser()
  const menuCategoryGenerator = () => {
    let tempMenuCategory = []

    // if(user.role.name == 'DRIVER') {
    //   tempMenuCategory.push(sidebarCategoryObjects['DRIVER_DASHBOARD'])
    // }

    Object.keys(sidebarCategoryObjects).forEach(category => {
      if(category == 'DRIVER_DASHBOARD' && user?.role?.name == 'DRIVER') {
        tempMenuCategory.push(sidebarCategoryObjects[category])
      }else {
        if(category != 'DRIVER_DASHBOARD') tempMenuCategory.push(sidebarCategoryObjects[category])
      }
    })

    

    return tempMenuCategory
  }
  const menuCategoryGeneratorDriver = () => {
    let tempMenuCategory = []

    Object.keys(sidebarCategoryObjectsDriver).forEach(category => {
        tempMenuCategory.push(sidebarCategoryObjectsDriver[category])
    })

    return tempMenuCategory
  }
  const menuCategoryGeneratorCashier = () => {
    let tempMenuCategory = []

    Object.keys(sidebarCategoryObjectsCashier).forEach(category => {
        tempMenuCategory.push(sidebarCategoryObjectsCashier[category])
    })

    return tempMenuCategory
  }

  return (
    // <Scrollbars>
      <Box style={{ margin: '0px 8px 100px 8px' }}>
        {user?.role?.name != 'DRIVER' && user?.role?.name != 'CASHIER' ? menuCategoryGenerator().map((category, index) => <SidebarCategories key={index} category={category} Icon={category.icon} isParent={category.isParent} route={category.route}/>) : <></>}
        {user?.role?.name == 'DRIVER' ? menuCategoryGeneratorDriver().map((category, index) => <SidebarCategories key={index} category={category} Icon={category.icon} isParent={category.isParent} route={category.route}/>) : <></>}
        {user?.role?.name == 'CASHIER' ? menuCategoryGeneratorCashier().map((category, index) => <SidebarCategories key={index} category={category} Icon={category.icon} isParent={category.isParent} route={category.route}/>) : <></>}
      </Box>
    // </Scrollbars>

  );
}

export default SidebarMenu
